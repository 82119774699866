export const skills = {
  Web: [
    { name: "Node JS", percent: 90 },
    { name: "Express JS", percent: 90 },
    { name: "React", percent: 90 },
    { name: "MongoDB", percent: 90 },
    { name: "MySQL", percent: 85 },
  ],
  Others: [
    { name: "Redux", percent: 80 },
    { name: "Context API", percent: 90 },
    { name: "Puppeteer", percent: 65 },
    { name: "Firebase", percent: 60 },
  ],
  Programming: [
    { name: "JavaScript", percent: 95 },
    { name: "C++", percent: 90 },
    { name: "C", percent: 90 },
    { name: "Java", percent: 60 },
  ],
  Tools: [
    { name: "Git", percent: 90 },
    { name: "Photoshop", percent: 75 },
    { name: "Trello", percent: 85 },
    { name: "Jira", percent: 85 },

  ],
}
