export const portfolios = {
  Web: [
    {
      type: "featured",
      name: "Bangla Camp",
      image: require("./image/banglacamp.jpg"),
      description:
        "A Camp review website where user can create account, login, post a camp review and comment on others camp",
      demo: "https://banglacamp.herokuapp.com/",
      source: "https://github.com/shahnewaztameem/BanglaCamp",
      tools: ["nodejs", "express js", "mongodb"],
    },
    {
      type: "featured",
      name: "Hungry Monster",
      image: require("./image/HungryMonster.jpg"),
      description:
        "Meal Searching website using Vanilla JS. User can search for meals and request to the API and fetch the data and show it to the UI",
      demo: "https://shahnewaztameem.github.io/Hungry-Monster-assignment/",
      source: "https://github.com/devsonket/devsonket.github.io",
      tools: ["Javascript", "JSON", "Bootstrap"],
    },
    {
      type: "featured",
      name: "World Rank",
      image: require("./image/worldRanks.jpg"),
      description:
        "Fetch All the world's country details from API and show it to the UI. User can search, sort and show the country details.",
      demo: "https://world-rank-shahnewaztameem.vercel.app/",
      source: "https://github.com/shahnewaztameem/world-rank",
      tools: ["Next Js", "CSS"],
    },
    {
      type: "regular",
      name: "Passport Authentication",
      description:
        "Passport Authentication using passport js to validate the user using local strategy",
      source: "https://github.com/shahnewaztameem/Passport-Authentication",
      tools: ["express", "mongodb"],
    },
    {
      type: "regular",
      name: "URL Shortener Microservice NodeJS",
      description:
        "Short the web url and send back a json data of the shortener url",
      demo: "https://intermediate-grand-becklespinax.glitch.me/",
      source:
        "https://github.com/shahnewaztameem/URL_Shortener_Microservice_NodeJS",
      tools: ["express", "mongodb"],
    },
    {
      type: "regular",
      name: "Form Validation",
      description: "Form Field validation Using JavaScript",
      source: "https://github.com/shahnewaztameem/JS-form-validation",
      tools: ["JavaScript", "regex"],
    },
  ],
  Others: [
    {
      type: "regular",
      name: "Corporate Landing Page",
      image: require("./image/corporate.jpg"),
      description: "PSD to HTML Design using HTML, CSS and Bootstrap",
      demo: "https://shahnewaztameem.github.io/corporate-landing-page/",
      source: "https://github.com/shahnewaztameem/corporate-landing-page",
      tools: ["Html", "CSS", "Bootstrap"],
    },
    {
      type: "regular",
      name: "10 Fast Fingers Bot",
      description: "Auto typing bot for 10 Fast Fingers website",
      source: "https://github.com/shahnewaztameem/10fastfingers-bot",
      tools: ["node js", "puppeteer"],
    },
    {
      type: "regular",
      name: "Web Scraping",
      description:
        "Web Scrapper using node js and puppeteer to scrap specific information from a website",
      source:
        "https://github.com/shahnewaztameem/web-sacraping-psychologytoday",
      tools: ["node js", "puppeteer"],
    },
  ],
}
